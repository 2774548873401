import { Link } from "gatsby"
import classNames from "classnames"
import moment from "moment"
import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Alert, Button, Form, Row, Col, InputGroup } from "react-bootstrap"
import { Control, Form as ReduxForm } from "react-redux-form"
import { ErrorList, validateNoSpecialCharAtBeginning } from "../components/validation"
import ErrorApi from "../components/error"

import { expectAuthenticationState } from "../components/authentication"
import getLicenseFileName from "../../../backend/src/licenses/license-file-name"
import { downloadFile } from "../utils/download"

import splitLicenses from "../utils/split-licenses"
import Loading from "../components/loading"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./my-licenses/my-licenses.module.css"

const MyLicensesPage = () => (
  <Layout>
    <SEO title="API & Tokens" />
    <h1 data-test="page-title">API & Tokens</h1>
    <MyLicensesList />
    <MyLicensesApiList />
  </Layout>
)

export default expectAuthenticationState({ connected: true, onboarded: true, orangeInternal: true })(
  MyLicensesPage,
)

class MyLicensesList extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false,
      currentLicenses: [],
      tokenName: undefined,
      tokenSecret: undefined,
      tokenId: undefined,
      tokenNameCopied: false,
      tokenSecretCopied: false,
      COLUMNS: [
        {
          dataField: "id",
          text: "Token ID",
          hidden: true,
          formatter: function displayTokenId(id) {
            return (
              <span className="license_row" data-test="id">
                {id}
              </span>
            )
          },
        },
        {
          dataField: "token_name",
          text: "Token Name",
          formatter: function displayTokenName(tokenName) {
            return (
              <span className="license_row" data-test="tokenName">
                {tokenName}
              </span>
            )
          },
        },
        {
          dataField: "token_creation",
          text: "Token Creation Date",
          formatter: function displayTokenCreation(tokenCreation) {
            return (
              <span className="license_row" data-test="tokenCreation">
                {moment(tokenCreation).format("DD/MM/YYYY")}
              </span>
            )
          },
        },
        {
          dataField: "token_exp",
          text: "Token Expiration Date",
          formatter: displayDateJSX,
        },
        {
          dataField: "download",
          text: "Action",
          formatter: this.deleteTokenJSX,
        },
      ],
      requestInProgress: false,
    }
  }
  /* eslint-disable no-unused-vars */
  render() {
    const {
      currentLicenses,
      loaded,
      requestInProgress,
      tokenSecret,
      tokenSecretCopied,
      requestError,
      errorMessage,
    } = this.state
    /* eslint-disable no-unused-vars */
    if (!loaded) {
      return (
        <>
          <h2>Please wait while we load your tokens</h2>
          <Loading />
        </>
      )
    }

    return (
      <>
        {requestError && <ErrorApi>{errorMessage}</ErrorApi>}

        <p><mark>From version 10.1 of Khiops, license files are no longer required.</mark><br />
          The licensing system of this site remains active for users of older versions. Note that
          Khiops is not unlicensed, having the same legal license agreement file as before.</p>

        <p>
          You may request licenses by API. It is convenient for the installation and
          use of Khiops in a cluster of machines or when an application uses Khiops
          inside docker. Please refer to <Link to="/tools/api-and-tokens">documentation</Link> before
          creating a new token and requesting licenses by API.
        </p>
        <br />
        <p>
          To create a <strong>new token</strong>, choose a name, then click on
          the following button:
        </p>
        <ReduxForm
          className="my-3"
          model="createTokenForm.data"
          onSubmit={values => this.createToken(values)}
        >
          <Form.Row>
            <Col md={10} lg={8} xl={6}>
              <Form.Group>
                <Form.Label htmlFor="tokenName" className="is-required">
                  Token name
                </Form.Label>
                <Control.text
                  type="text"
                  className="form-control"
                  model=".tokenName"
                  id="tokenNameField"
                  name="token"
                  // value={this.state.tokenName}
                  // onChange={e => this.setState({ tokenName: e.target.value })}
                  validators={{
                    required: val => val && val.length,
                    isValid: val => validateNoSpecialCharAtBeginning(val || "")
                  }}
                  data-test="create-token"
                />
                <ErrorList
                  model=".tokenName"
                  messages={{
                    isValid:
                      "Token should start with alphaNumeric character"
                  }}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          {!requestInProgress ? (
            <Button type="submit"
              style={{ verticalAlign: "initial" }}
            >
              {"Create a new token"}
            </Button>
          ) : (
            <Loading />
          )}

        </ReduxForm>
        <Row>
          <Col md={10} lg={8} xl={6}>
            {tokenSecret && (
              <Alert variant="warning">
                <span className="alert-icon">
                  <span className="sr-only">Warning</span>
                </span>
                <div>
                  Please keep the token secret that will be needed to
                  authenticate the use of the API. It will not be displayed
                  afterwards.
                </div>
              </Alert>
            )}

            {tokenSecret && (
              <div className="my-3">
                <Form.Group>
                  <Form.Label htmlFor="tokenSecretField">
                    Token secret
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      id="tokenSecretField"
                      type="text"
                      value={this.state.tokenSecret}
                      onChange={e =>
                        this.setState({ tokenName: e.target.value })
                      }
                      data-test="token-secret"
                      readOnly="readonly"
                    />
                    <InputGroup.Append>
                      <CopyToClipboard
                        text={this.state.tokenSecret}
                        onCopy={() =>
                          this.setState({
                            tokenSecretCopied: true,
                            tokenNameCopied: false,
                          })
                        }
                      >
                        <span
                          className="btn btn-secondary"
                          style={{ verticalAlign: "initial" }}
                        >
                          {tokenSecretCopied ? "Copied" : "Copy"}
                        </span>
                      </CopyToClipboard>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </div>
            )}
          </Col>
        </Row>
        <h2>My tokens</h2>
        <BootstrapTable
          keyField="id"
          wrapperClasses="table-responsive"
          data={currentLicenses}
          columns={this.state.COLUMNS}
          noDataIndication="You do not have any tokens yet. Click on 'Create a token' to get one"
        />
      </>
    )
  }

  async componentDidMount() {
    const response = await fetch("/api/my-tokens", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
    if (response.ok) {
      const currentLicenses = await response.json()
      this.setState({
        loaded: true,
        currentLicenses,
      })
    } else {
      throw new Error(`Invalid response : ${response.status} content : ${await response.text()}`)
    }
  }

  deleteTokenJSX = (cell, row, rowIndex) => {
    return (
      <div
        className="btn btn-primary"
        data-test="delete-token"
        onClick={() => {
          const tokenId = row.id
          this.deleteToken(tokenId)
        }}
      >
        Delete
      </div>
    )
  }

  async deleteToken(tokenId) {
    const response = await fetch("/api/my-tokens", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({ tokenId: tokenId }),
    })
    if (response.ok) {
      const currentLicenses = this.state.currentLicenses.filter(function (el) {
        return el.id !== tokenId
      })
      this.setState({ currentLicenses })
    } else {
      throw new Error(`Invalid response : ${response.status} content : ${await response.text()}`)
    }
  }

  async createToken(values) {
    this.setState({ requestError: false, requestInProgress: true })
    const response = await fetch("/api/my-tokens", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({ tokenName: values.tokenName }),
    })
    if (response.ok) {
      this.setState({ requestError: false, requestInProgress: false })
      const newToken = await response.json()
      const currentLicenses = this.state.currentLicenses.concat(newToken)
      this.setState({
        tokenSecret: newToken.token_secret_key,
        tokenId: newToken.id,
        currentLicenses: currentLicenses,
      })
    } else {
      this.setState({ requestError: true, requestInProgress: false, errorMessage: await response.text() })
    }
  }
}

function displayDateJSX(date) {
  const isExpired = moment(date).isBefore(moment())
  return (
    <div
      data-test="licenseExpirationDate"
      className={classNames(
        { ["expired_license"]: isExpired },
        "license_row",
      )}
    >
      {moment(date).format("DD/MM/YYYY")}
    </div>
  )
}

const COLUMNS = [
  {
    dataField: "tokenName",
    text: "Token Name",
    formatter: function displayTokenId(tokenName) {
      return (
        <span className="license_row" data-test="tokenName">
          {tokenName}
        </span>
      )
    },
  },
  {
    dataField: "computerName",
    text: "Computer Name",
    formatter: function displayComputerName(computerName) {
      return (
        <span className="license_row" data-test="computerName">
          {computerName}
        </span>
      )
    },
  },
  {
    dataField: "computerId",
    text: "Machine ID",
    formatter: function displayComputerId(computerId) {
      return (
        <span className="license_row" data-test="computerId">
          {computerId}
        </span>
      )
    },
  },
  {
    dataField: "expirationDate",
    text: "Expiration Date",
    formatter: displayDateJSX,
  },
  {
    dataField: "licenseType",
    text: "Version",
    formatter: function displayKhiopsVersion(licenseType) {
      switch (licenseType) {
        case "v8_orange":
          return (
            <span className="license_row" data-test="licenseType">
              8.0
            </span>
          )
        case "v8_external":
          return (
            <span className="license_row" data-test="licenseType">
              8.0
            </span>
          )
        case "v9_orange":
          return (
            <span className="license_row" data-test="licenseType">
              9.0
            </span>
          )
        case "v9_external":
          return (
            <span className="license_row" data-test="licenseType">
              9.0
            </span>
          )
        default:
          return (
            <span className="license_row" data-test="licenseType">
              10.0
            </span>
          )
      }
    },
  },
  {
    dataField: "download",
    text: "Action",
    formatter: downloadLicenseJSX,
  },
]

class MyLicensesApiList extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false,
      currentLicenses: [],
      previousLicenses: [],
      expiredLicenses: [],
    }
  }

  render() {
    const {
      currentLicenses,
      previousLicenses,
      expiredLicenses,
      loaded,
    } = this.state

    if (!loaded) {
      return (
        <>
          <h3>Please wait while we load your licenses</h3>
          <Loading />
        </>
      )
    }

    return (
      <>
        <h2>My licences requested by API</h2>
        <BootstrapTable
          keyField="id"
          wrapperClasses="table-responsive"
          data={currentLicenses}
          columns={COLUMNS}
          noDataIndication={
            (
              <span>You do not have any licenses requested by API yet. Please refer to <Link to="/tools/api-and-tokens">documentation</Link>.</span>
            )
          }
        />

        {previousLicenses.length > 0 && (
          <>
            <br />
            <h3 data-test="page-title">My previously requested licenses</h3>
            <p>
              The licenses listed below are only here for archiving purposes,
              please use the more recent licenses above as they expire later.
            </p>
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="id"
              data={previousLicenses}
              columns={COLUMNS}
            />
          </>
        )}

        {expiredLicenses.length > 0 && (
          <>
            <br />
            <h3 data-test="page-title">My expired licenses</h3>
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="id"
              data={expiredLicenses}
              columns={COLUMNS}
            />
          </>
        )}
      </>
    )
  }

  async componentDidMount() {
    const response = await fetch("/api/my-licenses/token", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
    if (response.ok) {
      const licenses = await response.json()
      const {
        currentLicenses,
        previousLicenses,
        expiredLicenses,
      } = splitLicenses(licenses, moment().format("YYYY-MM-DD"))
      this.setState({
        loaded: true,
        currentLicenses,
        previousLicenses,
        expiredLicenses,
      })
    } else {
      throw new Error(
        `Invalid response : ${response.status
        } content : ${await response.text()}`
      )
    }
  }
}

function downloadLicenseJSX(cell, row) {
  return (
    <div
      className="btn btn-primary"
      data-test="download-license"
      onClick={() => {
        downloadLicense(getLicenseFileName(row), row.licenseFile)
      }}
    >
      Download
    </div>
  )
}

function downloadLicense(filename, licenseContent) {
  const blob = new window.Blob([licenseContent], {
    type: "application/octet-stream",
  })
  downloadFile(filename, blob)
}
